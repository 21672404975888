import React from "react";
import imgBack from "../../assets/imgs/icons/icon_back.png";
import imgForward from "../../assets/imgs/icons/icon_forward.png";
export const Header = () => {
  return (
    <div className="flex py-3 gap-[40px] justify-center w-full">
      <img src={imgBack} className="w-[35px] cursor-pointer" alt="back" />
      <img src={imgForward} className="w-[30px] cursor-pointer" alt="forward" />
    </div>
  );
};
