import Draggable from "react-draggable";

const PannableText = ({ text, font }) => {
  return (
    <Draggable>
      <div className={`${font} font-[14px] inline-block`}>{text}</div>
    </Draggable>
  );
};

export default PannableText;
