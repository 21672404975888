// import React from "react";
// import Draggable from "react-draggable";
// import { Resizable } from "re-resizable";

// const PannableImage = ({ url }) => {
//   return (
//     <Draggable>
//       <Resizable
//         defaultSize={{
//           width: 200,
//           height: 100,
//         }}
//         style={{
//           background: `url(${url})`,
//           backgroundSize: "contain",
//           backgroundRepeat: "no-repeat",
//         }}
//         lockAspectRatio={true}
//       ></Resizable>
//     </Draggable>
//   );
// };

// export default PannableImage;
import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";

const PannableImage = ({ url }) => {
  const [size, setSize] = useState({ width: 200, height: 100 });
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      // Set size to image dimensions
      setSize({ width: 200, height: (200 * img.height) / img.width });
    };
    img.src = url;
  }, [url]);
  return (
    <Draggable>
      <Resizable
        size={size}
        style={{
          background: `url(${url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        lockAspectRatio={true}
      ></Resizable>
    </Draggable>
  );
};

export default PannableImage;
