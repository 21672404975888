import React from "react";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <div className="flex p-2 justify-between border rounded-lg">
      <Link
        to="/"
        className="border-[#3c7a67] rounded-lg border-2 px-6 py-2 text-[#3c7a67] font-bold hover:bg-gray-100"
      >
        Back
      </Link>
      <Link
        to="/"
        className="bg-[#3c7a67] rounded-lg  px-6 py-2 text-white font-bold hover:bg-[#306958]  "
      >
        Next
      </Link>
    </div>
  );
};
