import React from "react";
import { PanelButton } from "../common/PanelButton";
import icon_sign from "../../assets/imgs/icons/icon_sign.png";

import icon_stamp from "../../assets/imgs/icons/icon_stamp.png";
import icon_sign_date from "../../assets/imgs/icons/icon_sign_date.png";
import icon_initial from "../../assets/imgs/icons/icon_initial.png";
import icon_case from "../../assets/imgs/icons/icon_case.png";
import icon_email from "../../assets/imgs/icons/icon_email.png";
import icon_office from "../../assets/imgs/icons/icon_office.png";
import icon_user_avatar from "../../assets/imgs/icons/icon_user_avatar.png";
export const SignPanel = ({
  onDateSigned,
  onInitial,
  onStamp,
  onSignature,
}) => {
  return (
    <div className="border border-[#d4d4d4] rounded-xl w-[270px] p-3 flex-shrink-0 ">
      <div className="flex flex-col gap-1">
        <PanelButton
          icon={icon_sign}
          title="Signature"
          onbtnhandle={onSignature}
        />
        <PanelButton
          icon={icon_initial}
          title="Initial"
          onbtnhandle={onInitial}
        />
        <PanelButton icon={icon_stamp} title="Stamp" onbtnhandle={onStamp} />
        <PanelButton
          icon={icon_sign_date}
          onbtnhandle={onDateSigned}
          title="Date Signed"
        />
      </div>
      <div className="pt-4"></div>
      <div className="flex flex-col gap-1">
        <PanelButton icon={icon_user_avatar} title="Name" />
        <PanelButton icon={icon_email} title="Email" />
        <PanelButton icon={icon_office} title="Company" />
        <PanelButton icon={icon_case} title="Title" />
      </div>
    </div>
  );
};
