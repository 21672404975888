import React, { useState, useEffect, useRef } from "react";
import icon_gallery from "../../assets/imgs/icons/icon_gallery.png";
import icon_confirm from "../../assets/imgs/icons/icon_confirm.png";
import CanvasDraw from "react-canvas-draw";
const Setting = ({ action }) => {
  const [initials, setInitials] = useState("");
  const [step, setStep] = useState(0);
  const [font, setFont] = useState("font-custom");
  const [fullname, setFullName] = useState("");
  const [showdialog, setShowDialog] = useState(true);
  const canvasRef = useRef(null);
  const [stamp, setStamp] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [sign, setSign] = useState();
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth * 0.54); // Initial width
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight * 0.6);
  const containerRef = useRef(null); // Ref for the container div

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setCanvasWidth(containerRef.current.offsetWidth);
      }
    };

    // Set initial size based on parent container's width
    if (containerRef.current) {
      setCanvasWidth(containerRef.current.offsetWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setStamp(reader.result);
        console.log(stamp);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);
  function handleImageChange(event) {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
  }
  const handleFileButtonClick = () => {
    fileInputRef.current.click(); // Programmatically trigger click on file input
  };
  const onhandleConfirm = (e) => {
    e.preventDefault();
    setShowDialog(false);
    action({ fullname, initials, font, stamp, sign });
  };
  const getImageFromCanvas = () => {
    const canvas = canvasRef.current.canvasContainer.childNodes[1]; // Access the canvas element
    const imageURL = canvas.toDataURL();
    setSign(imageURL);
  };
  return (
    <div>
      {showdialog && (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[#000000]/50">
          <div className="min-w-[60%] min-h-[470px] bg-white rounded-3xl">
            <form className="h-32 w-full bg-[#3c7a67]/20 rounded-t-3xl flex items-center px-10 ">
              <div className="flex-[50%] gap-8 flex items-center">
                <label className="text-[20px] font-custom">Full Name</label>
                <input
                  type="text"
                  id="fullname"
                  className="text-[20px] p-2 px-4 rounded-md focus:outline-none focus:border-none font-custom"
                  placeholder="Full Name "
                  value={fullname}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="flex-[50%] flex justify-between">
                <div className="flex items-center gap-8 ">
                  <label className="text-[20px] font-custom">Initials</label>
                  <input
                    type="text"
                    id="initials"
                    className="text-[20px] p-2 px-4 rounded-md focus:outline-none focus:border-none font-custom "
                    placeholder="Initials"
                    value={initials}
                    onChange={(e) => setInitials(e.target.value)}
                  />
                </div>
                <button>
                  <img
                    src={icon_confirm}
                    alt="confirm"
                    className="rounded-full w-[20px] "
                    onClick={onhandleConfirm}
                  />
                </button>
              </div>
            </form>
            <div className="pt-10 flex justify-center items-center ">
              <div>
                <button
                  className={` px-6 py-2 rounded-l-lg hover:bg-[#3c7a67] hover:text-white ${
                    step === 0 ? "bg-[#3c7a67] text-white" : "bg-[#3c7a67]/20"
                  }`}
                  onClick={() => setStep(0)}
                >
                  Choose
                </button>
                <button
                  className={` px-6 py-2  hover:bg-[#3c7a67] hover:text-white ${
                    step === 1 ? "bg-[#3c7a67] text-white" : "bg-[#3c7a67]/20"
                  }`}
                  onClick={() => setStep(1)}
                >
                  Draw
                </button>
                <button
                  className={` px-6 py-2 rounded-r-lg hover:bg-[#3c7a67] hover:text-white ${
                    step === 2 ? "bg-[#3c7a67] text-white" : "bg-[#3c7a67]/20"
                  }`}
                  onClick={() => setStep(2)}
                >
                  upload
                </button>
              </div>
            </div>
            {step === 0 && (
              <div className="py-5 w-[90%] mx-auto flex justify-between  items-end border-b-4 border-black ">
                <p className={`text-[100px] ${font}`}>
                  {fullname ? fullname : "Willon Morris"}
                </p>
                <div className="flex flex-col border rounded-md">
                  <p className="p-2 text-left px-6 text-[20px] font-custom">
                    Change Signature
                  </p>
                  <button
                    className="p-2 text-left px-6 text-[20px] "
                    onClick={() => setFont("font-custom")}
                  >
                    Will Morris
                  </button>
                  <button
                    className="p-2 text-left px-6 text-[20px] font-dancing1"
                    onClick={() => setFont("font-dancing1")}
                  >
                    Will Morris
                  </button>
                  <button
                    className="p-2 text-left px-6 text-[20px] font-dancing2"
                    onClick={() => setFont("font-dancing2")}
                  >
                    Will Morris
                  </button>
                </div>
              </div>
            )}
            {step === 1 && (
              <div
                ref={containerRef}
                className="w-[90%] mx-auto  border-b-4 border-black py-4 h-[250px]"
              >
                <CanvasDraw
                  ref={canvasRef}
                  canvasWidth={canvasWidth}
                  canvasHeight={220}
                  brushRadius={3}
                  onChange={getImageFromCanvas}
                  lazyRadius={0}
                />
              </div>
            )}
            {step === 2 && (
              <div className="w-[90%] mx-auto flex justify-center items-center pt-10">
                <button
                  className="flex justify-start flex-col items-center"
                  onClick={handleFileButtonClick}
                >
                  <img src={icon_gallery} alt="gallery" className="w-[40px]" />
                  <p className="text-[20px]">Select Image</p>
                </button>
                {stamp && <img src={stamp} className="p-10 w-[200px]" />}
              </div>
            )}
            <div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Setting;
