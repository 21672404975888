import React from "react";

const ProgressBar = () => {
  return (
    <div className="bg-[#3c7a67] p-3 rounded-lg flex items-center font-custom">
      <div className="flex items-center w-[50%] justify-between mx-auto">
        <p className="font-bold text-[16px] text-white font-[]">Add Document</p>
        <p className="font-bold text-[30px] text-white">&#8594;</p>
        <p className="font-bold text-[16px] text-white">Select Recepient</p>
        <p className="font-bold text-[30px] text-white">&#8594;</p>
        <p className="font-bold text-[16px] text-white">Prepare</p>
        <p className="font-bold text-[30px] text-white">&#8594;</p>
        <p className="font-bold text-[16px] text-white/60">Review</p>
      </div>
    </div>
  );
};

export default ProgressBar;
