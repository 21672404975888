import { useState, useRef } from "react";
import arrow_up from "../../assets/imgs/icons/arrow_up.png";
export const UploadPDF = ({ setPdfFile, setPDFUploading, setFileName }) => {
  const uploading = useState(true);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      setFileName(file.name);
      setPDFUploading(false);
    } else {
      alert("Please upload a PDF file.");
    }
  };
  return (
    <div className="h-full flex-col  rounded-xl w-full">
      <h1 className="font-bold text-[30px] text-center pt-[100px]">
        {uploading ? "ESign documents with ease" : "Send Document for signing"}
      </h1>

      <div className="flex flex-col ">
        <div className="bg-white mx-auto pt-4">
          <div className="border-dotted  border-2  border-[#000000]/60 rounded-xl py-20 px-44 ">
            <img src={arrow_up} className="mx-auto pb-3" alt="arrow_up" />
            <p className="font-bold text-[14px]">
              Drop documents here to get started
            </p>
          </div>
        </div>
        <p className="text-center text-[15px] py-7">or</p>
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept="application/pdf"
          ref={fileInputRef}
        />
        <button
          onClick={handleClick}
          className="bg-[#3c7a67] mx-auto rounded-lg  px-6 py-2 text-white  hover:bg-[#306958]  "
        >
          Upload from computer
        </button>
      </div>
    </div>
  );
};
