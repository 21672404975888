import React from "react";

export const PanelButton = ({ icon, title, onbtnhandle }) => {
  return (
    <button
      className="w-full border py-2 px-6 flex items-center gap-3 rounded-xl  hover:font-bold"
      onClick={onbtnhandle}
    >
      <img src={icon} className="w-[18px]" alt="icon" />
      <span className="text-[14px] font-custom ">{title}</span>
    </button>
  );
};
