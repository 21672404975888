import React, { useState, useEffect, useRef } from "react";

import ProgressBar from "../components/common/ProgressBar";
import { Footer } from "../containers/layouts/Footer";
import { UploadPDF } from "../containers/home/UploadPDF";
import { Signature } from "../containers/home/Signature";

import { LoadingUpload } from "../containers/home/LoadingUpload";

import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.js";
import "pdfjs-dist/legacy/build/pdf.worker.entry";
const Home = () => {
  const [uploadPDF, setUploadPDF] = useState(true);
  const [pdfUploading, setPDFUploading] = useState(true);
  const [pdfFile, setPdfFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const combinedRef = useRef({
    canvasRef: null,
    pref: null,
  });
  useEffect(() => {
    if (pdfFile) {
      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        try {
          pdfjsLib.GlobalWorkerOptions.workerSrc =
            "//cdnjs.cloudflare.com/ajax/libs/pdf.js/[version]/pdf.worker.min.js";

          const pdfData = new Uint8Array(event.target.result);
          const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
          const canvas = combinedRef.current.canvasRef;
          const ctx = canvas.getContext("2d");

          let totalHeight = 0;
          let maxWidth = 0;
          const scale = 1.8;

          // Calculate total height and max width
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale });
            totalHeight += viewport.height;
            maxWidth = Math.max(maxWidth, viewport.width);
          }

          // Resize the canvas to fit all pages
          canvas.height = totalHeight;
          canvas.width = maxWidth;

          let accumulatedHeight = 0;

          // Render each page
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale });

            const renderContext = {
              canvasContext: ctx,
              viewport,
            };

            ctx.translate(0, accumulatedHeight);
            await page.render(renderContext).promise;
            ctx.translate(0, -accumulatedHeight); // Reset translation

            accumulatedHeight += viewport.height;
          }

          const previewCanvas = combinedRef.current.pref;
          const previewCtx = previewCanvas.getContext("2d");

          // Set the size of the preview canvas
          // Adjust these values as needed to fit your UI
          const previewWidth = 270;
          const previewHeight = 150;

          // Calculate the scaling factor needed to fit the entire canvas into the preview size
          const scaleWidth = previewWidth / maxWidth;
          const scaleHeight = previewHeight / totalHeight;
          const scale1 = Math.max(scaleWidth, scaleHeight);

          // Calculate the new scaled dimensions
          const scaledWidth = maxWidth * scale1;
          const scaledHeight = totalHeight * scale1;

          // Resize the preview canvas to the calculated dimensions
          previewCanvas.width = scaledWidth;
          previewCanvas.height = scaledHeight;

          // Clear the preview canvas in case anything was previously rendered on it
          previewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);

          // Draw the main canvas content onto the preview canvas, scaled to fit
          previewCtx.drawImage(
            canvas,
            0,
            0,
            maxWidth,
            totalHeight,
            0,
            0,
            scaledWidth,
            scaledHeight
          );
        } catch (error) {
          console.error("Error while loading or rendering PDF: ", error);
        }
      };

      fileReader.readAsArrayBuffer(pdfFile);
      setUploadPDF(false);

      // Assuming setUploadPDF is meant to reset a state for uploading; consider removing or adjusting its placement as needed.
    }
  }, [pdfFile]);

  return (
    <div className="h-screen bg-[#f0fbf7]/50 p-3 font-custom">
      <div className="flex flex-col h-full mx-auto">
        <ProgressBar />
        {uploadPDF ? (
          pdfUploading ? (
            <UploadPDF
              setPdfFile={setPdfFile}
              setPDFUploading={setPDFUploading}
              setFileName={setFileName}
            />
          ) : (
            <LoadingUpload
              type="spin"
              color="#000000"
              width={100}
              height={200}
            />
          )
        ) : (
          <Signature ref={combinedRef} fileName={fileName} />
        )}
        <Footer />
      </div>
    </div>
  );
};
export default Home;
