import React from "react";
import ReactLoading from "react-loading";

export const LoadingUpload = ({ type, color, height, width }) => {
  return (
    <div className="h-full flex-col  rounded-xl w-full">
      <h1 className="font-bold text-[30px] text-center pt-[100px]">
        ESign documents with ease
      </h1>

      <div className="flex flex-col ">
        <div className="bg-white mx-auto pt-4">
          <div className="  rounded-xl py-20 px-44 bg-[#3c7a67]/10">
            <div className="flex justify-center">
              <ReactLoading type={type} color={color} height={30} width={30} />
            </div>
            <p className="font-bold text-[20px] pt-3">Uploading documents</p>
          </div>
        </div>
      </div>
    </div>
  );
};
