import React, {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import { Header } from "../layouts/Header";
import { SignPanel } from "../../components/home/SignPanel";
import PannableText from "./PannableText ";
import PannableImage from "./PannableImage";
import icon_delete from "../../assets/imgs/icons/icon_delete.png";
import sample_stamp from "../../assets/imgs/icons/sample_stamp.png";

import sample_sign from "../../assets/imgs/icons/sample_sign.png";
import Setting from "./Setting";
// import PdfViewer from "../../components/home/PdfViewer";
export const Signature = forwardRef((props, ref) => {
  const canvasRef = useRef(null);
  const pref = useRef(null);
  const [components, setComponents] = useState([]);
  const [fullname, setFullName] = useState();
  const [initials, setInitials] = useState();
  const [font, setFont] = useState();
  const [stamp, setStamp] = useState();
  const [sign, setSign] = useState();
  useImperativeHandle(ref, () => ({
    get canvasRef() {
      return canvasRef.current;
    },
    get pref() {
      return pref.current;
    },
  }));
  const divRef = useRef(null);
  const [style, setStyle] = useState({});
  useEffect(() => {
    const updateDivSizeAndPosition = () => {
      if (canvasRef.current) {
        const { offsetLeft, offsetTop, clientWidth, clientHeight } =
          canvasRef.current;

        setStyle({
          position: "absolute",
          left: `${offsetLeft}px`,
          top: `${offsetTop}px`,
          width: `${clientWidth}px`,
          height: `${clientHeight}px`,
        });
      }
    };
    updateDivSizeAndPosition();

    // Optional: Update on window resize or other events that might change the canvas size/position
    window.addEventListener("resize", updateDivSizeAndPosition);
    return () => window.removeEventListener("resize", updateDivSizeAndPosition);
  }, []);
  const action = ({ fullname, initials, font, stamp, sign }) => {
    setFullName(fullname);
    setInitials(initials);
    setFont(font);
    setStamp(stamp);
    setSign(sign);
  };
  const onDateSigned = () => {
    setComponents((currentComponents) => [
      ...currentComponents,
      <PannableText
        key={currentComponents.length}
        text="2024/03/30"
        font={font}
      />,
    ]);
  };
  const onSignature = () => {
    setComponents((currentComponents) => [
      ...currentComponents,
      <PannableImage key={currentComponents.length} url={sign} />,
    ]);
  };
  const onInitial = () => {
    setComponents((currentComponents) => [
      ...currentComponents,
      <PannableText
        key={currentComponents.length}
        text={initials}
        font={font}
      />,
    ]);
  };
  const onStamp = () => {
    setComponents((currentComponents) => [
      ...currentComponents,
      <PannableImage key={currentComponents.length} url={stamp} />,
    ]);
  };
  return (
    <div className="flex h-full flex-col bg-white rounded-xl">
      <Header />
      <div className="flex p-3 h-full gap-3">
        <SignPanel
          onDateSigned={onDateSigned}
          onSignature={onSignature}
          onInitial={onInitial}
          onStamp={onStamp}
        />
        <div className="w-full  h-full p-3 border border-[#d4d4d4] rounded-xl ">
          <div className="w-full  bg-slate-400 overflow-y-scroll overflow-x-hidden h-[750px] relative">
            <canvas ref={canvasRef} className=" mx-auto max-w-[900px] " />
            <div ref={divRef} style={style}>
              {components}
            </div>
          </div>
        </div>
        <div className="border border-[#d4d4d4] rounded-xl h-full w-[270px] flex-shrink-0 p-3">
          <div className="w-full h-[150px] bg-slate-400 overflow-hidden">
            <canvas ref={pref} className=" mx-auto max-w-[900px] " />
          </div>
          <div className="flex justify-between items-center">
            <p className="font-custom font-[14px]">{props.fileName}</p>
            <button>
              <img src={icon_delete} alt="delete" />
            </button>
          </div>
        </div>
      </div>
      <Setting action={action} />
    </div>
  );
});
